<template>

    <div style="animation-duration: .3s" key="$route.params.projectId" class="w-2/3  p-4 flex flex-col  relative " v-if="$route.params.projectId ">

        <loader :loading="loading"/>

        <router-link :to="{ name: 'ProjectsBilling' }" class="flex items-center mb-2">
            <div class="header-btn ml-0 mr-2">
                <i class="mdi mdi-view-grid-outline"></i>
            </div>
            <div class="text-font-gray">Return to project's grid</div>
        </router-link>

        <div class="box h-full p-0 flex flex-col overflow-hidden" v-if="projectSummary">

            <div class="flex mb-6 p-2">

                <div class="text-lg font-bold">
                    <div class="mb-2">{{ projectSummary.project_name }}</div>
                    <div class="text-xs text-font-gray">
                        Client: {{ projectSummary.client_name }}
                    </div>
                </div>

                <div class="ml-auto text-font-gray text-right">
                    <div class="mb-2">#{{ projectSummary.id }}</div>
                    <div class="text-xs text-good">{{ projectSummary.status }}</div>
                </div>

            </div>

            <div class="flex items-center mb-6 p-2">

                <div class="w-20 flex-none text-tinny text-font-gray">Progress</div>

                <div class="w-full">

                    <div class="bg-progress h-2 rounded-full overflow-hidden">
                        <div class="h-full bg-aux rounded-full"
                             :style="'width:' + projectSummary.progress + '%'">
                        </div>
                    </div>

                </div>

                <div class="w-20 text-lg flex-none text-right font-bold">
                    {{ projectSummary.progress | numberFormat(2) }}%
                </div>

            </div>

            <div class="text-font-gray mb-10 p-2">
                EM : {{ projectSummary.em }}<br>
                EP : {{ projectSummary.ep }}
            </div>

            <div class="p-2 h-full overflow-auto  flex flex-col">

                <div class="flex mb-16">

                    <div class="w-1/3 text-center flex flex-col">

                        <div class="text-font-dark mb-2">ANSR</div>

                        <div class="text-4xl font-semibold mb-1">{{ projectSummary.ner | reduceBigNumbers(2) }}€</div>

                        <div class="mb-4 text-lg" :class="{'text-success': projectSummary.future_ner_vs_initial >= 0, 'text-danger': projectSummary.future_ner_vs_initial < 0}">
                            {{ projectSummary.future_ner_vs_initial | reduceBigNumbers() }}€
                        </div>

                        <div v-if="projectSummary.is_tax==false" class="flex">
                            <div class="w-1/2 text-center">
                                <div class="text-tiny text-font-gray mb-2">Initial ANSR</div>
                                <div class="text-xl font-semibold">
                                    {{ projectSummary.initial_ner | reduceBigNumbers() }}€
                                </div>
                            </div>
                            <div class="w-1/2 text-center">
                                <div class="text-tiny text-font-gray mb-2">Future ANSR</div>
                                <div class="text-xl font-semibold">
                                    {{ projectSummary.future_ner | reduceBigNumbers() }}€
                                </div>
                            </div>
                        </div>

                        <div v-else class="text-center">
                            <div class="text-tiny text-font-gray mb-2">Initial ANSR</div>
                            <div class="text-xl font-semibold">
                                {{ projectSummary.initial_ner | reduceBigNumbers() }}€
                            </div>
                        </div>

                    </div>

                    <div class="w-1/3 text-center flex flex-col">
                        <div class="text-font-dark mb-2">Margin</div>
                        <div class="text-4xl font-semibold mb-1">
                            {{ projectSummary.margin | numberFormat(2) }}%
                        </div>
                        <div class="mb-4 text-lg" :class="{'text-success': projectSummary.margin_percent >= 0, 'text-danger': projectSummary.margin_percent < 0}">
                            {{ projectSummary.margin_percent | numberFormat(2) }} ptos.
                        </div>
                        <div class="text-tiny text-font-gray mb-2">Initial Margin</div>
                        <div class="text-xl font-semibold">
                            {{ projectSummary.initial_margin | numberFormat(2) }}%
                        </div>
                    </div>

                    <div class="w-1/3 text-center flex flex-col">
                        <div class="text-font-dark mb-2">Average Rate</div>
                        <div class="text-4xl font-semibold mb-1">
                            {{ projectSummary.average_rate | reduceBigNumbers(2) }}€/h
                        </div>
                        <div class="text-success mb-4 text-lg" :class="{'text-success': projectSummary.average_rate_percent >= 0, 'text-danger': projectSummary.average_rate_percent < 0}">
                            <span v-if="projectSummary.average_rate_percent>0">+</span>{{
                            projectSummary.average_rate_percent | numberFormat(2) }}%
                        </div>
                        <div class="text-tiny text-font-gray mb-2">Future Average Rate</div>
                        <div class="text-xl font-semibold">
                            {{ projectSummary.future_average_rate | reduceBigNumbers(2) }}€/h
                        </div>
                    </div>

                </div>

                <div class="grid grid-flow-col grid-cols-2 grid-rows-3 gap-8  mb-8 mt-auto">
                    <div v-for="(el, k, i) in list" :key="i" class="flex items-center px-8">
                        <div class="text-font-gray">{{ el.name }}</div>
                        <div class="text-2xl ml-auto font-bold">

                            <span v-if="el.dec!==null">{{ projectSummary[k] | numberFormat(el.dec) }}</span>
                            <span v-else>{{ projectSummary[k] }}</span>
                            <template v-if="projectSummary[k] !== null">
                                {{ el.sufix || '' }}
                            </template>
                        </div>
                    </div>
                </div>

                <div class="grid grid-cols-2  grid-flow-col gap-8 mb-4 mt-auto">
                    <div v-for="(el, k, i) in secondList" :key="i" class="flex items-center px-8">
                        <div class="text-font-gray">{{ el.name }}</div>
                        <div class="text-2xl ml-auto font-bold">
                            {{ projectSummary[k] | numberFormat(el.dec) }}
                            <template v-if="projectSummary[k] !== null">{{ el.sufix || '' }}
                            </template>
                        </div>
                    </div>
                </div>

            </div>
            <div :class="getFlexAlertClass(projectSummary.alert.length)">
                <div v-if="projectSummary.alert" v-for="(alert,index) in projectSummary.alert" :key="index"
                     class="p-4 flex items-center ml-2"
                     :class="[projectSummary.alert.length >= 2 ? 'w-1/' + projectSummary.alert.length : '' ,alert.type==='positive'?'text-success bg-success-30':'text-danger bg-danger-30']">
                    <i class="mdi mdi-alert text-2xl"></i>
                    <div class="ml-2">{{ alert.name }}</div>
                </div>
            </div>

        </div>

        <div v-if="projectSummaryWarning" class="flex h-full overflow-hidden items-center justify-center">

            <div class="bg-warning-30 text-warning p-8 rounded-lg shadow-lg text-center">
                <i class="mdi mdi-hand-left text-6xl"></i>
                <div class="text-lg font-bold">
                    {{ selectedRol.role_type_name }}
                    <template v-if="selectedRol.role_type_link !== 'presidencia'">
                        + {{ selectedRol.role_name }}
                    </template>
                </div>
                <div class="mb-2">
                    you are not allowed to see this project data
                </div>
                <separator class="mb-2" style="opacity:.5"/>
                <div class="flex items-center">
                    <div class="mr-1">Change your rol or</div>
                    <router-link :to="{ name: 'ProjectsBilling' }" class="flex items-center">
                        <div class="">Return to project's grid</div>
                        <div class="pill-TheEssential  ml-2">
                            <i class="mdi mdi-view-grid-outline"></i>
                        </div>
                    </router-link>
                </div>
            </div>

        </div>

    </div>

</template>

<script>
    import {state} from '@/store';
    import { firstListLabelsSingleProject, secondListLabelsSingleProject } from '@/utils/LabelsSingleProject'

    export default {
        name: 'SingleProject',
        data() {
            return {
                stringFilter: '',
                stringModel: '',
                loading: false,
                data: null,
                alerts: [],
                projectSummary: null,
                projectSummaryWarning: false,
                list: firstListLabelsSingleProject,
                secondList: secondListLabelsSingleProject,
            }
        },
        methods: {
            getFlexAlertClass(length) {
                return {
                    'flex': length >= 2,
                }
            },
            loadProjects() {
                this.loading = true
                this.projectSummaryWarning = false
                this.projectSummary = null
                if (this.$route.params.projectId) {
                    this.axios.get('projects_management/summary', {params: this.params}).then(response => {
                        this.projectSummary = response.data.data;
                        this.loading = false;
                    })
                }
            }
        },
        computed: {
            params() {
                if (this.$route.params.projectId) {
                    return {...state.globalParams, ...{idProject: this.$route.params.projectId}}
                }
                return state.globalParams
            },
            selectedRol() {
                return state.selectedRol
            },
        },
        watch:{
            params(){
                if (this.$route.params.projectId) {
                    this.loadProjects();
                }
            },
            selectedRol(){
                if (this.$route.params.projectId) {
                    this.loadProjects();
                }
            }
        },
        mounted() {
            if (this.$route.params.projectId) {
                this.loadProjects();
            }
        }
    }
</script>
