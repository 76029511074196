export const firstListLabelsSingleProject = {
        expenses: {name: 'Expenses', sufix: '€', dec: 2},
        pending_collection: {name: 'Pending Collection', sufix: '€', dec: 2},
        unbilled: {name: 'Unbilled', sufix: '€', dec: 2},
        last_hour_charged: {name: 'Last Hour Charged', dec: null},
        charged_hours: {name: 'Charged Hours', sufix: 'h', dec: 0},
        charged_planned_hours: {name: 'Charged Planned Hours', sufix: 'h', dec: 0},
    }

export const secondListLabelsSingleProject = {
        p_m_c: {name: 'PMC', dec: 0, sufix: 'd'},
        as_reserves: {name: 'As reserves', dec: 0, sufix: ''},
    }